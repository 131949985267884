/* App.css */

/* Christmas Theme Colors */
:root {
  --christmas-red: #d42426;
  --christmas-green: #165b33;
  --snow-white: #fff;
  --holly-green: #146b3a;
  --candy-red: #ea4630;
  --gold: #ffd700;
}

body {
  background: url('https://www.transparenttextures.com/patterns/snow.png'), var(--christmas-red);
  color: var(--snow-white);
  font-family: 'Arial', sans-serif;
}

.container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.form-wrapper {
  padding: 20px;
  width: 100%;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  color: #333;
  border: 3px solid var(--christmas-green);
  position: relative;
}

/* Add holly decoration to corners */
.form-wrapper::before,
.form-wrapper::after {
  content: '🎄';
  position: absolute;
  font-size: 2rem;
}

.form-wrapper::before {
  top: 10px;
  left: 10px;
}

.form-wrapper::after {
  top: 10px;
  right: 10px;
}

h1 {
  color: var(--christmas-red);
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  font-family: 'Mountains of Christmas', cursive;
}

/* Form Elements Styling */
form div {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: var(--christmas-green);
}

input[type="text"],
input[type="number"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 2px solid #ddd;
  border-radius: 8px;
  font-size: 16px; /* Prevents iOS zoom on focus */
}

/* Radio button groups */
.radio-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

/* Input groups (for wishlist, siblings, parents) */
.input-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .input-group {
    flex-direction: row;
    align-items: center;
  }
  
  .input-group button {
    width: auto;
  }
}

/* Buttons */
button {
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 8px;
  width: 100%;
  margin: 5px 0;
  background-color: var(--christmas-green);
  color: var(--snow-white);
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

@media (min-width: 768px) {
  button {
    width: auto;
  }
}

button:hover {
  background-color: var(--holly-green);
  transform: translateY(-2px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Input Groups (Wishlist & Siblings) */
.input-group {
  display: flex;
  gap: 1rem;
  align-items: center;
  margin-bottom: 1rem;
}

.add-button {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

/* Loading State */
.loading {
  text-align: center;
  padding: 2rem;
  background: var(--snow-white);
  border-radius: 15px;
  color: var(--christmas-red);
  border: 3px solid var(--christmas-green);
  max-width: 800px;
  margin: 2rem auto;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

/* Success Message */
.sent-message {
  text-align: center;
  padding: 2rem;
  background: var(--snow-white);
  border-radius: 15px;
  color: var(--christmas-green);
  border: 3px solid var(--christmas-green);
  max-width: 800px;
  margin: 2rem auto;
  box-sizing: border-box;
}

.sent-message h2 {
  color: var(--christmas-red);
  margin-bottom: 1rem;
}

/* Responsive Design */
@media (max-width: 600px) {
  .container {
    margin: 1rem;
    padding: 1rem;
  }
  
  .input-group {
    flex-direction: column;
    gap: 0.5rem;
  }
  
  button {
    width: 100%;
  }
}

/* Animation for the loading state */
@keyframes snowfall {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.loading h2 {
  animation: snowfall 1s ease-out;
}

.santa-image {
  max-width: 200px;
  width: 100%;
  height: auto;
  margin: 0 auto;
  display: block;
}

.submit-button-wrapper {
  text-align: center;
  margin-top: 30px;
}

.submit-button-wrapper button {
  background-color: #d4363e;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.submit-button-wrapper button:hover {
  background-color: #b62d33;
}

.letter-view {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.letter-content {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-top: 2rem;
}

.wishlist {
  margin: 1rem 0;
  padding-left: 2rem;
}

.message-to-santa {
  margin: 2rem 0;
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 4px;
}

.letter-footer {
  margin-top: 2rem;
  text-align: right;
}

.address {
  font-style: italic;
  color: #666;
}

.error, .not-found {
  text-align: center;
  color: #d32f2f;
  padding: 2rem;
}

.santa-message {
  color: #333;
}

.write-another-btn, .print-btn, .share-btn {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1.1em;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.write-another-btn {
  background-color: #c41e3a;
}

.write-another-btn:hover {
  background-color: #a01830;
}

.print-btn {
  background-color: #2c5282;
}

.print-btn:hover {
  background-color: #2a4365;
}

.share-btn {
  background-color: #4267B2;
}

.share-btn:hover {
  background-color: #365899;
}

.email-notice {
  text-align: center;
  color: #d4363e;
  margin: 0;
  font-size: 1em;
  font-weight: 500;
  padding: 0 10px;
}

/* Reindeer radio buttons */
.reindeer-options {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
}

.footer {
  text-align: center;
  padding: 20px;
  margin-top: 30px;
  color: var(--snow-white);
  font-size: 0.9rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
  font-weight: 500;
}

/* Add these new styles */
@media print {
  @page {
    margin: 0.5cm 1.5cm;
    size: portrait;
  }

  body {
    background: none;
  }

  .no-print {
    display: none !important;
  }

  .container {
    margin: 0;
    padding: 0;
    max-width: none;
  }

  .letter-view {
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;
    max-width: none;
  }

  .letter-content {
    font-size: 11pt;
    line-height: 1.4;
    margin-top: 1rem;
  }

  .wishlist {
    margin: 0.5rem 0;
    padding-left: 1rem;
  }

  .message-to-santa {
    margin: 1rem 0;
    padding: 0.5rem;
  }

  .letter-footer {
    margin-top: 1rem;
  }
}

/* Add this for the button group */
.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

@media (max-width: 600px) {
  .button-group {
    flex-direction: column;
  }
  
  .share-btn {
    order: 3;
    width: 100%;
  }
}

.santa-message {
  font-family: 'Comic Neue', cursive;
  font-size: 1.2rem;
}

.preview-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 10px;
  margin: 15px 10px 20px;
}

.preview-btn {
  background-color: #f8f9fa;
  color: #6c757d;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 8px 16px;
  font-size: 0.9em;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 1px 2px rgba(0,0,0,0.05);
}

.preview-btn:hover {
  background-color: #e9ecef;
  border-color: #ced4da;
  color: #495057;
  transform: translateY(-1px);
}